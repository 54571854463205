import ArubaIcon from 'resources/propertyPNGs/WStore_Icon_Aruba.png';
import AtmoreIcon from 'resources/propertyPNGs/WStore_Icon_Atmore.png';
import BethlehemIcon from 'resources/propertyPNGs/WStore_Icon_Bethlehem.png';
import ChicagoIcon from 'resources/propertyPNGs/WStore_Icon_Chicago.png';
import CuracaoIcon from 'resources/propertyPNGs/WStore_Icon_Curacao.png';
import MiamiIcon from 'resources/propertyPNGs/WStore_Icon_Miami.png';
import MontgomeryIcon from 'resources/propertyPNGs/WStore_Icon_Montgomery.png';
import WetumpkaIcon from 'resources/propertyPNGs/WStore_Icon_Wetumpka.png';

export const APP_ENV = process.env.REACT_APP_ENVIRONMENT;
export const APP_ENV_EMPLOYEE = 'employee';
export const APP_ENV_DEV = 'development';
export const APP_ENV_TEST = 'test';
export const APP_ENV_PROD = 'production';

export function isEmployeeEnv() {
  return APP_ENV === APP_ENV_EMPLOYEE;
}

export function isDevEnv() {
  return APP_ENV === APP_ENV_DEV;
}

export function isTestEnv() {
  return APP_ENV === APP_ENV_TEST;
}

export function isProdEnv() {
  return APP_ENV === APP_ENV_PROD;
}

export function isLocalServer() {
  return process.env.NODE_ENV === 'development';
}

export const APP_HOST = process.env.REACT_APP_HOST;

export const YOUTUBE_CHANNEL_URL = `https://www.youtube.com/channel/${process.env.REACT_APP_YOUTUBE_CHANNEL_ID}`;

export const FONT_FAMILY_BODY_LIST = [
  'Montserrat',
  'Helvetica',
  'Arial',
  'sans-serif'
];
export const FONT_FAMILY_TITLE_LIST = [
  'Lilita One',
  'Arial Black',
  'Helvetica',
  'Tahoma',
  'sans-serif'
];

// Call center phone number for general issues
export const SUPPORT_GUEST_SERVICE_PHONE = '866-946-3360';
export const SUPPORT_GUEST_SERVICE_PHONE_TEL = 'tel:+1-866-946-3360';

// Community manager phone number for WCC-specific issues
export const SUPPORT_SOCIAL_PHONE = '866-946-3387';
export const SUPPORT_SOCIAL_PHONE_TEL = 'tel:+1-866-946-3387';

export const VENDOR_AGS = 'AGS';
export const VENDOR_SPIN_GAMES = 'SpinGames';
export const VENDOR_RED_RAKE = 'RedRake';
export const VENDOR_WIND_CREEK = 'WindCreek';
export const VENDOR_FLOWPLAY = 'Flowplay';
export const VENDOR_BOOMING_GAMES = 'BoomingGames';

export const PASSWORD_MIN_LENGTH = 8;
export const PASSWORD_MAX_LENGTH = 64;
export const DISPLAY_NAME_MIN_LENGTH = 3;
export const DISPLAY_NAME_MAX_LENGTH = 31;
export const ADMIN_DISPLAY_NAME_MAX_LENGTH = 50;

// Event types
export const PROMOTION_EVENT = 'promotions';
export const SPECIAL_EVENT = 'special';
export const ENTERTAINMENT_EVENT = 'entertainment';

// community event promotion types
export const BONUS_GAME = 'BONUS_GAME';
export const EARN_GET = 'EARN_GET';
export const ELECTRONIC_DRAWING = 'ELECTRONIC_DRAWING';
export const FOOD_CREDIT = 'FOOD_CREDIT';
export const HOT_SEATS = 'HOT_SEATS';
export const TICKET_DRAWING = 'TICKET_DRAWING';
export const HOLIDAY_SHOPPING_DOLLARS = 'HOLIDAY_SHOPPING_DOLLARS';
export const COME_AND_GET = 'COME_AND_GET';
export const FREE_PLAY = 'FREE_PLAY';
export const SPA_SPECIAL = 'SPA_SPECIAL';
export const TOURNAMENT = 'TOURNAMENT';
export const ONLINE_DRAWING = 'ONLINE_DRAWING';
export const ONLINE_TOURNAMENT = 'ONLINE_TOURNAMENT';

// Promo Perks
export const PRIZE_MULTIPLIER = 'PRIZE_MULTIPLIER';
export const AUTO_ADVANCE = 'AUTO_ADVANCE';
export const AUTO_ADVANCE_PLUS = 'AUTO_ADVANCE_PLUS';
export const AUTO_WIN = 'AUTO_WIN';
export const PRIZE_BOOSTER = 'PRIZE_BOOSTER';
export const PRIZE_DOUBLER = 'PRIZE_DOUBLER';
export const PICK_FIRST = 'PICK_FIRST';
export const VIRTUAL_WIN = 'VIRTUAL_WIN';
export const WIN_FROM_HOME = 'WIN_FROM_HOME';
export const ADD_A_FRIEND = 'ADD_A_FRIEND';
export const MYSTERY_BOX = 'MYSTERY_BOX';
export const CASH_IS_KING = 'CASH_IS_KING';
export const EXTRA_ENTRIES = 'EXTRA_ENTRIES';
export const EXTRA_EXTRA = 'EXTRA_EXTRA';
export const FREE_ENTRY = 'FREE_ENTRY';
export const DECKED_OUT = 'DECKED_OUT';
export const ALL_MINE = 'ALL_MINE';
export const FREE_FOR_ME = 'FREE_FOR_ME';
export const TRIGGERED = 'TRIGGERED';
export const FREE_FOOD_FRENZY = 'FREE_FOOD_FRENZY';
export const WATERFALL = 'WATERFALL';
export const SHARE_THE_WIN = 'SHARE_THE_WIN';
export const OH_SNAP = 'OH_SNAP';
export const JETSETTER = 'JETSETTER';
export const STAND_BY = 'STAND_BY';
export const BOUNCEBACK = 'BOUNCEBACK';
export const DO_OVER = 'DO_OVER';
export const FREE_SPIN = 'FREE_SPIN';
export const CALL_MY_NAME = 'CALL_MY_NAME';

// Verse Perks
export const VERSE_PERKS_AUTO_SPIN = 'AUTO_SPIN';
export const VERSE_PERKS_AUTO_WIN = 'AUTO_WIN';
export const VERSE_PERKS_CALL_MY_NAME = 'CALL_MY_NAME';
export const VERSE_PERKS_CURRENCY_EXCHANGE = 'CURRENCY_EXCHANGE';
export const VERSE_PERKS_DOUBLEU_SCORE = 'DOUBLEU_SCORE';
export const VERSE_PERKS_FREE_SPIN = 'FREE_SPIN';
export const VERSE_PERKS_MAGNET = 'MAGNET';
export const VERSE_PERKS_PRIZE_DOUBLER = 'PRIZE_DOUBLER';
export const VERSE_PERKS_REPEAT_CHALLENGE = 'REPEAT_CHALLENGE';
export const VERSE_PERKS_RESPIN_WHEEL = 'RESPIN_WHEEL';
export const VERSE_PERKS_TIME_WARP = 'TIME_WARP';
export const VERSE_PERKS_WMORE = 'WMORE';

// community event category types -- these values match the values in the database.
export const PROMOTIONS = 'promotions';
export const ENTERTAINMENT = 'entertainment';
export const SPECIAL = 'special';

// Account Types
export const CMP_ACCOUNT = 'cmp';
export const PLS_ACCOUNT = 'pls';

// Property constants
export const PROPERTY_ONLINE = 'ONLINE';
export const PROPERTY_ATMORE = 'WCA';
export const PROPERTY_BETHLEHEM = 'WCB';
export const PROPERTY_MONTGOMERY = 'WCM';
export const PROPERTY_WETUMPKA = 'WCW';
export const PROPERTY_CHICAGO = 'CHICAGO';
export const PROPERTY_ARUBA = 'ARUBA';
export const PROPERTY_CURACAO = 'CURACAO';

export const PROPERTIES = {
  [PROPERTY_ATMORE]: 'Atmore',
  [PROPERTY_BETHLEHEM]: 'Bethlehem',
  [PROPERTY_MONTGOMERY]: 'Montgomery',
  [PROPERTY_WETUMPKA]: 'Wetumpka'
};

export const PROPERTY_CHICAGO_SOUTHLAND = 'Chicago Southland';

export const ADDRESS_ATMORE = '303 Poarch Rd. Atmore, AL 36502';
export const ADDRESS_BETHLEHEM = '77 Wind Creek Blvd Bethlehem, PA 18015';
export const ADDRESS_MONTGOMERY =
  '1801 Eddie L. Tullis Road Montgomery, AL 36117';
export const ADDRESS_WETUMPKA = '100 River Oaks Drive Wetumpka, AL 36092';
export const ADDRESS_ARUBA = '82 L.G. Smith Boulevard Oranjestad, Aruba';
export const ADDRESS_CURACAO = 'Baden Powellweg Willemstad, Curaçao';
export const ADDRESS_MIAMI = '450 NW 37th Ave, Miami, FL 33125';
export const ADDRESS_CHICAGO = '17300 S. Halsted East Hazel Crest, IL 60429';

export const PROPERTY_TIME_ZONE = {
  [PROPERTY_ONLINE]: 'America/Chicago',
  [PROPERTY_ATMORE]: 'America/Chicago',
  [PROPERTY_BETHLEHEM]: 'America/New_York',
  [PROPERTY_MONTGOMERY]: 'America/Chicago',
  [PROPERTY_WETUMPKA]: 'America/Chicago',
  [PROPERTY_ARUBA]: 'America/Aruba',
  [PROPERTY_CURACAO]: 'America/Curacao'
};

export const CMP_LOCATIONS = [
  'Aruba',
  'Atmore',
  'Bethlehem',
  'Chicago Southland',
  'Curacao',
  'Montgomery',
  'Wetumpka'
];

export const LOCATION_DATA = {
  Aruba: {
    id: 'Aruba',
    title: 'Aruba',
    image: ArubaIcon,
    address: ADDRESS_ARUBA,
    name: 'Aruba',
    url: 'https://windcreek.com/Aruba',
    initiator: 'casino-property-menu-aruba',
    bookingUrl:
      'https://www.marriott.com/en-us/hotels/auabr-renaissance-wind-creek-aruba-resort/overview/',
    assumeEnabled: true
  },
  Atmore: {
    id: 'Atmore',
    title: 'Atmore, AL',
    image: AtmoreIcon,
    address: ADDRESS_ATMORE,
    name: 'Atmore',
    url: 'https://windcreek.com/Atmore',
    initiator: 'casino-property-menu-atmore',
    bookingUrl: 'https://windcreek.com/Atmore/book-now',
    assumeEnabled: true
  },
  Bethlehem: {
    id: 'Bethlehem',
    title: 'Bethlehem, PA',
    image: BethlehemIcon,
    address: ADDRESS_BETHLEHEM,
    name: 'Bethlehem',
    url: 'https://windcreek.com/Bethlehem',
    initiator: 'casino-property-menu-bethlehem',
    bookingUrl: 'https://windcreek.com/Bethlehem/book-now',
    assumeEnabled: true
  },
  [PROPERTY_CHICAGO_SOUTHLAND]: {
    id: 'Chicago Southland',
    title: 'Chicago Southland',
    image: ChicagoIcon,
    address: ADDRESS_CHICAGO,
    name: 'Chicago Southland',
    url: 'https://windcreek.com/chicagosouthland',
    initiator: 'casino-property-menu-chicago',
    assumeEnabled: false
  },
  Curacao: {
    id: 'Curacao',
    title: 'Curacao',
    image: CuracaoIcon,
    address: ADDRESS_CURACAO,
    name: 'Curacao',
    url: 'https://windcreek.com/Curacao',
    initiator: 'casino-property-menu-curacao',
    bookingUrl:
      'https://www.marriott.com/en-us/hotels/curbr-renaissance-wind-creek-curacao-resort/overview/',
    assumeEnabled: true
  },
  Miami: {
    id: 'Miami',
    title: 'Miami, FL',
    image: MiamiIcon,
    address: ADDRESS_MIAMI,
    name: 'Magic City',
    url: 'https://magiccitycasino.com/',
    initiator: 'casino-property-menu-miami',
    assumeEnabled: false
  },
  Montgomery: {
    id: 'Montgomery',
    title: 'Montgomery, AL',
    image: MontgomeryIcon,
    address: ADDRESS_MONTGOMERY,
    name: 'Montgomery',
    url: 'https://windcreek.com/Montgomery',
    initiator: 'casino-property-menu-montgomery',
    bookingUrl: 'https://windcreek.com/Montgomery/book-now',
    assumeEnabled: true
  },
  Wetumpka: {
    id: 'Wetumpka',
    title: 'Wetumpka, AL',
    image: WetumpkaIcon,
    address: ADDRESS_WETUMPKA,
    name: 'Wetumpka',
    url: 'https://windcreek.com/Wetumpka',
    initiator: 'casino-property-menu-wetumpka',
    bookingUrl: 'https://windcreek.com/Wetumpka/book-now',
    assumeEnabled: true
  }
};

export function getPreferredLocationData(location) {
  return LOCATION_DATA[location];
}

// Gem Types
export const NUGGET = 'NUGGET';
export const RUBY = 'RUBY';
export const SAPPHIRE = 'SAPPHIRE';
export const WCHIP = 'WCHIP';
export const WSCORE = 'WSCORE';

// Winners Screen timer states
export const PAUSED = 'Paused';
export const RUNNING = 'Running';

// (useMediaQuery() || withMediaQuery()) Breakpoints
export const LG_BREAKPOINT_AND_UP = '(min-width: 1025px)'; // Large screens / Desktops
export const MD_BREAKPOINT_AND_UP = '(min-width: 641px)'; // Medium screens / Tablets
export const SM_BREAKPOINT_AND_DOWN = '(max-width: 640px)'; // Small screens / Mobile devices
export const XS_SCREEN_BREAKPOINT_AND_DOWN = '(max-width: 480px)'; // Extra small screens / Mobile devices

// Configuration Entry Key
export const CV_WEB_UNRELEASED_PROPERTIES = 'cv.web.UnreleasedProperties';
export const CV_WEB_FEEDBACK_FORM_URL = 'cv.web.FeedbackFormUrl';
export const CV_WEB_PUBLIC_PREVIEW_ALLOWED = 'cv.web.public.preview.allowed';
export const CV_WEB_HOMELAYOUTUPDATE_ENABLED =
  'cv.web.HomeLayoutUpdate.enabled';
export const CV_WEB_SOCIALPANELTABS_ENABLED = 'cv.web.SocialPanelTabs.enabled';
export const CV_WEB_NEW_ONBOARD_EXP_ENABLED = 'cv.web.NewOnboardExp.enabled';
export const CV_WEB_GAME_LAYOUT_UPDATE_ENABLED =
  'cv.web.GameLayoutUpdate.enabled';
export const CV_WEB_SAPPHIRES_NEW_UX_ENABLED = 'cv.web.SapphiresNewUX.enabled';
export const CV_WEB_NEW_WSTORE_UI_ENABLED = 'cv.web.NewWStoreUI.enabled';

// Chicago Property
export const PROPERTIES_NOT_RELEASED = 'Properties.NotReleasedProperties';
export const PROPERTIES_CHICAGO_PROPERTY_SWITCH_ENABLED =
  'Properties.ChicagoPropertySwitch.Enabled';
export const WSTORE_CHICAGO_WSTORE_AVAILABLE = 'Wstore.ChicagoWstoreAvailable';
export const WSTORE_PARTNERS_CATEGORY_ENABLED =
  'WStore.PartnersCategory.enabled';
export const FEATURE_NEW_REWARDS_PROGRAM_ENABLED =
  'feature.NewRewardsProgram.enabled';
